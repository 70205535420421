<template>
<div class="legkovrace-footer">
  <div class="legkovrace-footer__container container">
    <div class="legkovrace-footer__content">
      <div class="legkovrace-footer__left">
        <span>©&nbsp;{{ new Date().getFullYear() }}, Legkovrace | </span>
         <a href="mailto:info@legkovrace.ru" class="legkovrace-footer__link">info@legkovrace.ru</a>
         <span> | для заявок спортшкол: </span>
         <a href="mailto:reg@legkovrace.ru" class="legkovrace-footer__link">reg@legkovrace.ru</a>
         <span> | для СМИ: </span>
         <a href="mailto:press@legkovrace.ru" class="legkovrace-footer__link">press@legkovrace.ru</a>
      </div>

      <div class="legkovrace-footer__right">
        <div class="legkovrace-footer__socials">
          <a href="https://t.me/legkovrace" target="_blank" class="legkovrace-footer__social-item">
            <Icon name="telegram" />

            <svg class="svg-icon" aria-hidden="true">
                <use xlink:href="#icon-"></use>
            </svg>
          </a>

          <a href="https://vk.com/legkovrace" target="_blank" class="legkovrace-footer__social-item">
            <Icon name="vkontakte"/>
          </a>

          <a href="https://www.youtube.com/channel/UCaosr3XpKQrzNT2PpnwDpIQ" target="_blank" class="legkovrace-footer__social-item">
            <Icon name="youtube"/>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import Icon from '@rr-component-library/icon/src/main';

export default {
  name: 'LegkovRaceFooter',

  components: {
    Icon,
  },

  methods: {
    toggleFooterItem(e) {
      e.target.parentNode.classList.toggle('rr-footer-group_collapsed');
    },
  },
};
</script>

<style lang="scss" scoped>
.legkovrace-footer {
  position: relative;
  margin-top: auto;
  padding: 20px 0;
  background-color: $color-dark-gray;
  font-family: $font-family-base;
  color: $color-white-85;

  .rr-button__text {
    font-size: 14px;
  }

  .rr-button__icon .rr-icon {
    width: 16px;
    height: 16px;
  }

  &__socials {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__social-item {
    display: block;
    width: 32px;
    height: 32px;
    color: inherit;
    padding: 6px;
    max-width: 100%;
    transition: 0.3s ease-in-out;

    &:hover,
    &:focus {
      color: $color-white;
    }

    &:not(:last-child) {
      margin-right: 8px;
    }

    .rr-icon {
      display: block;
    }
  }

  &__content {
    display: flex;

    @media (max-width: 1023px) {
      flex-direction: column;
      align-items: center;
      gap: 16px;
    }

    @media (min-width: 1024px) {
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
      gap: 16px;
    }
  }

  &__left {
    @media (max-width: 1023px) {
      text-align: center;
    }
  }

  &__right {
    display: flex;
    order: -1;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    @media (min-width: 768px) {
      flex-direction: row;
      gap: 40px;
    }

    @media (min-width: 1024px) {
      order: 0;
    }
  }

  &__link {
    font-family: inherit;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    color: $color-white-85;
    text-decoration: none;
  }
}
</style>
